import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import DocSidebar from "../components/docSidebar"

const Doc = ({ data }) => {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark

    return (
        <Layout
          title={frontmatter.title}
        >
            <div className="container mt-12 mb-12 doc">
                <div className="flex">
                    <div className="mr-32 flex flex-col">
                        <DocSidebar />
                    </div>

                    <div className="flex-grow doc-body">
                        <div className="mb-8 px-4 py-3 bg-orange-100 border border-orange-300 rounded text-orange-800">Documentation is still in progress and subject to change.</div>
                        <div dangerouslySetInnerHTML={{ __html: html }}></div>
                    </div>
                </div>{/* flex */}
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        template
        title
      }
    }
  }
`

export default Doc